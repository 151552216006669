<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">会员列表</div>
        <!-- <el-button @click="handleExport">导出</el-button> -->
      </div>
      <div class="search">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="注册开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
        />
        <el-input v-model="mobile" type="text" placeholder="请输入手机号" clearable></el-input>
        <el-select v-model="reg_appid" placeholder="请选择注册平台" clearable>
          <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>

    <div class="statistics">
      <el-tag effect="plain" size="large">用户总数{{ total }}</el-tag>
    </div>

    <el-table :data="tableData.items" v-loading="loading">
      <el-table-column prop="mobile" label="手机号码"></el-table-column>
      <el-table-column prop="coupon_sum_total_num" label="领取优惠券数量"></el-table-column>
      <el-table-column prop="used_sum_discount_num" label="已使用优惠券数量"></el-table-column>
      <el-table-column label="注册平台">
        <template #default="scope">
          {{ scope.row.reg_app_name?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间"></el-table-column>
    </el-table>

    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import userModel from '@/model/user'
import appModel from '@/model/application'
import commonHandle from '@/hooks/useCommon'
import util from '@/lin/util/util'
import { ElMessage } from 'element-plus/lib/components'

export default {
  setup() {
    const time = ref([])
    const mobile = ref()
    const reg_appid = ref()
    const total = ref(0)
    const appList = ref([])

    const getData = async pageQuery => {
      if (mobile.value && !util.checkLength(mobile.value, [10, 11])) return ElMessage.warning('请输入完整的手机号码')

      loading.value = true
      let res = await userModel.getUserList({
        mobile: mobile.value || null,
        reg_appid: reg_appid.value || null,
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
      })
      loading.value = false
      tableData.value = res
      return res
    }

    const { tableData, loading, pageParam, pageEmit } = commonHandle(getData)

    // 获取注册平台列表
    const getAppList = async () => {
      let res = await appModel.getApplicationListAll()
      appList.value = res
    }

    // 获取注册会员数
    const getRegidterCount = async () => {
      let res = await userModel.getRegidterCount({
        reg_appid: reg_appid.value || null,
      })
      total.value = res.total_count
    }

    const handleExport = () => {}

    onMounted(() => {
      getAppList()
      getRegidterCount()
      getData()
    })

    return {
      loading,
      tableData,
      pageParam,
      time,
      mobile,
      total,
      appList,
      reg_appid,
      pageEmit,
      getRegidterCount,
      getAppList,
      handleExport,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
